import React, { FC } from 'react';
import { Deploy } from '../../interfaces/deploy.interface';
import { HeaderAvatar } from './components/Avatar';
import { DeployButton } from './components/DeployButton';
import { DeployProgress } from './components/DeployProgress';
import { Menu } from './components/Menu';

import styles from './Header.module.scss';

interface IHeader {
  handleDeploy: () => void;
  currentDeploy?: Deploy | null;
}

export const Header: FC<IHeader> = ({ handleDeploy, currentDeploy }) => {
  return (
    <div className={styles['container']}>
      <div className={styles['header']}>
        <Menu />
        <DeployButton onClick={handleDeploy} currentDeploy={currentDeploy} />
        <DeployProgress deploy={currentDeploy} />
        <HeaderAvatar />
      </div>
    </div>
  );
};
